import React from "react"
import VisibilitySensor from "react-visibility-sensor"

import { HexagonTitle } from "../elements/hexagon-title"
import { SectionTitle } from "../elements/section-title"
import { SectionText } from "../elements/section-text"
import { QuoteBox } from "../elements/quote-box"

import { LeadershipMain } from "../styles/leadership.styles"
import { RevealImage } from "../elements/reveal-image"
import { useAgreementState } from "../context/global-agreement-context"

export const LeadershipSection = ({ image1Mobile, image1, image2, title, firstText, quoteImage, quoteText, quoteName, quoteLabel, imageText, hexagonData: { name, sectionNum, } }) => {
  
  const { popupTermsStatus } = useAgreementState();
  
  return (
    <LeadershipMain>
      {image1Mobile && <div className="mobile-only"><RevealImage image={image1Mobile} variation="from-right" /></div>}
      <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }}>
        {({ isVisible }) =>
          <div className="flex-wrap">
            <div className={`main-col to-animate ${isVisible && 'accepted' === popupTermsStatus ? 'is-in-vp' : ''}`}>
              <HexagonTitle number={sectionNum}>
                {name}
              </HexagonTitle>

              <SectionTitle text={title} responsive={[{ breakpoint: 767, charLimit: 6 }]} />

              {firstText && <SectionText variation={"large-text"} text={firstText} />}
            </div>

            <div className={`side-col desktop-only to-animate ${isVisible && 'accepted' === popupTermsStatus ? 'is-in-vp' : ''}`}>
              {image1 && <RevealImage alt="Brevan Howard Investment" image={image1} variation="from-right" />}
            </div>
          </div>
        }
      </VisibilitySensor>

      <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }}>
        {({ isVisible }) =>
          <div className={`quote-col to-animate ${isVisible && 'accepted' === popupTermsStatus ? 'is-in-vp' : ''}`}>
            <QuoteBox text={quoteText} name={quoteName} label={quoteLabel} image={quoteImage} />
          </div>
        }
      </VisibilitySensor>

      <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }}>
        {({ isVisible }) =>
          <div className={`img-col-wrap flex-wrap to-animate ${isVisible && 'accepted' === popupTermsStatus ? 'is-in-vp' : ''}`}>
            <div className="img-text-col">
              {imageText && <SectionText variation={"large-text"} text={imageText} />}
            </div>
            <div className="img-col">
              {image2 && <RevealImage alt="Brevan Howard Investment" image={image2} variation="from-right" />}
            </div>
          </div>
        }
      </VisibilitySensor>
    </LeadershipMain >
  )
}
